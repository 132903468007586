// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-aninha-en-js": () => import("./../src/pages/aninha.en.js" /* webpackChunkName: "component---src-pages-aninha-en-js" */),
  "component---src-pages-aninha-pt-js": () => import("./../src/pages/aninha.pt.js" /* webpackChunkName: "component---src-pages-aninha-pt-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-civil-js": () => import("./../src/pages/civil.js" /* webpackChunkName: "component---src-pages-civil-js" */),
  "component---src-pages-game-room-js": () => import("./../src/pages/game-room.js" /* webpackChunkName: "component---src-pages-game-room-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

